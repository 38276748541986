@import "./variables";

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  text-transform: none;
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 200ms ease-in-out;
  border-radius: 5px;
  opacity: 1;
  height: 4rem;

  &:hover {
    cursor: pointer;
    color: unset;
  }
  &:focus {
    box-shadow: none;
    outline: none !important;
  }
  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.btn--xm {
    font-size: 1em;
    height: 3rem;
  }

  &.btn--sm {
    font-size: 1em;
    letter-spacing: 0.02em;
  }

  &.btn--lg {
    width: 100%;
    font-size: 1.3rem;
    letter-spacing: 0.04em;
  }
  &.btn--primary,
  &.btn--primary:hover {
    background: $primary;
    color: $white;
  }

  &.btn--primary--bordered,
  &.btn--primary--bordered:hover {
    border: 1px solid $primary;
    color: $primary;
    background: unset;
  }
}
