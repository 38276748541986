.input {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(88, 153, 219, 0.1);
  border-radius: 2px;
  height: 60px;
  padding: 10px 15px;
  position: relative;
  margin: 16px 0 10px;
  input {
    position: absolute;
    bottom: 0;
    height: 50%;
    left: 0;
    width: 100%;
    padding: 10px 15px;
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(10, 58, 100, 0.24);
    }
  }
  .label {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #666666;
    font-family: "gilroy-bold";
  }
  .password-wrapper {
    height: 30px;
  }
  .password {
    z-index: 100;
  }
}

.input-text-error {
  color: red;
}
