@import "assets/styles/scss/variables";

.dashbase {
  display: flex;
  width: 100%;
  --sidebar-width: 150px;
  --custom-transition: all 0.4s;
}

.dash__content {
  min-height: 90vh;
}

.sidebar {
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  margin-left: calc(-1 * var(--sidebar-width));
  top: 0;
  left: 0;
  z-index: 999;
  background-color: $primary-light;
  color: $primary !important;
  font-size: 1.2em;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 80%);
  transition: var(--custom-transition);
  overflow-y: scroll;
  top: 0;
  left: 0;
  height: 100vh;
  position: sticky;
  overscroll-behavior: contain;
}

.sidebar.active {
  margin-left: 0;
}

.sidebar__close-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.sidebar__nav {
  padding-top: 2rem;
  padding-bottom: 3.3rem;
}

.sidebar__nav__item {
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
}

.sidebar__nav__item__link {
  margin: auto;
  padding: 1rem;
  background: $white;
  box-shadow: 0px 4px 20px #f4f8ff;
  width: 110px;
  text-align: center;
  font-family: gilroy-bold;

  p {
    opacity: 0.51;
    margin: 0;
  }
}

.sidebar__nav__item__link:hover,
.sidebar__nav__item__link[data-active="true"] {
  cursor: pointer;
  color: $primary;
  p {
    opacity: 1;
  }
  svg path {
    fill: $primary;
  }
}

.sidebar__nav__item__link:hover {
  text-decoration: none;
}

.sidebar__nav__item__link:hover::before,
.sidebar__nav__item__link[data-active="true"]::before {
  max-height: 100%;
}

.main-content {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  padding: 10px;
  transition: var(--custom-transition);
}

.sidebar__footer {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.navbar-toggler {
  &:not(.collapsed) {
    span {
      &:nth-child(1) {
        left: 0px;
        top: 16px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        opacity: 1;
      }
      &:nth-child(2) {
        height: 12px;
        visibility: hidden;
        background-color: transparent;
      }
      &:nth-child(3) {
        left: 0px;
        top: -10px;
        width: 30px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        opacity: 1;
      }
    }
  }
  span {
    display: block;
    background-color: $primary;
    height: 2px;
    width: 30px;
    margin-top: 4px;
    margin-bottom: 6px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    position: relative;
    left: 0;
    opacity: 1;

    &:nth-child(3) {
      width: 20px;
    }
    &:nth-child(1),
    &:nth-child(3) {
      -webkit-transition: transform 0.85s ease-in-out;
      -moz-transition: transform 0.85s ease-in-out;
      -o-transition: transform 0.85s ease-in-out;
      transition: transform 0.85s ease-in-out;
    }
  }
}

@media (min-width: 1024px) {
  .sidebar {
    margin-left: 0;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  }

  .sidebar.active {
    margin-left: calc(-1 * var(--sidebar-width));
  }

  .sidebar__close-icon {
    display: none;
  }

  .main-content {
    width: calc(100% - var(--sidebar-width));
  }

  .main-content.active {
    width: 100%;
  }
}
