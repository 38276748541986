$primary: #17568b;
$primary-light: #FAFBFD;


// NEUTRAL COLORS
$white: #ffffff;
$dark: #071B2B;
$black: #000000;
$gray: #505050;
$gray-med: #908e8e;
$gray-light: #aaaaaa;
$danger: #c81a2f;
$light: #E5E5E5

